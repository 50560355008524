

.buttonFacture {
    cursor: pointer;
    width: 177px;
    margin-left: 23px;
    border-radius: 6px;
    background-color: white;
    font-size: medium;
}

.buttonFacture:hover {
    background-color: blue;
    font-weight: 500;
    color: white;
}



