.flexrow {
  display: flex;
}
.flexrow > * {
  flex: 1;
  margin: 1em;
}











