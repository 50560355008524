/* Ajoutez ces règles dans votre feuille de style (CSS) */

/* Supprime la box-shadow de l'élément input en focus */
.ReactSearchAutocomplete input:focus {
    box-shadow: none;
}

/* Supprime la box-shadow de l'élément StripeElement en focus (si nécessaire) */
/* Assurez-vous d'ajuster la classe en fonction de votre implémentation réelle */
.StripeElement--focus {
    box-shadow: none;
    transition: none;
}
input, .StripeElement {
    display: block;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}
.small-input {
    width: 70px; /* ou la largeur souhaitée pour les inputs avec num_serie */
  }
  
  .large-input {
    width: 150px; /* ou la largeur souhaitée pour les inputs sans num_serie */
  }
  .react-search-autocomplete {
    z-index: 10000 !important;
  }
  .react-search-autocomplete .options-container {
    position: absolute; /* ou position: fixed; */
    top: 100%; /* ou ajustez la valeur selon vos besoins */
    left: 0;
    z-index: 10000;
  }
    