

.card-container1 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  height: 122px;
  gap: 28px;
}
.card1 {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 0;
  border-radius: 13px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(196, 205, 224, 0.2);
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  min-width: 0;
  position: relative;
  width: 100%;
}
/* .card {
  width: 30%; 
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 30px;
  height: 100px;
} */

.card1 .title {
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 10px;
 }
.title{
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.card.active {
  border-color: #007bff; /* Couleur de la bordure pour la carte active */
}

.table-container1 {
  margin-top: 15px;
}

 

.table-responsive1 {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: block;
  overflow-x: auto;
  width: 100%;
}
/* .modal {
  display: none;  
  position: fixed;  
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%;  
  height: 100%;  
  overflow: auto;  
  background-color: rgb(0,0,0);  
  background-color: rgba(0,0,0,0.4);  
  padding-top: 60px;
} */

/* Modal Content/Box */
/* .modal-content {
  background-color: #fefefe;
  margin: 5% auto;  
  padding: 20px;
  border: 1px solid #888;
  width: 80%;  
} */

/* The Close Button */
/* .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
} */

/* .close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} */
/* Style pour la carte (card) */
.client-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Style pour le dropdown des clients */
.client-dropdown {
  position: relative;
  display: inline-block;
}

.client-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1;
}

.client-dropdown-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.client-dropdown-content li {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.client-dropdown:hover .client-dropdown-content {
  display: block;
}

.client-col {
  width: 170px !important;
  /* padding-right: 170px !important; */
}
.my-custom-width {
  width: 150px; /* ajustez la largeur en conséquence */
}
.inputTable{
  width: 62px;
}