@media print {
  @page {
    size: A4;
    margin: 0; /* Ajustez si nécessaire pour les marges d'impression */
  }
  
  .dataTables_filter {
    display: none !important;
  }

  .dataTables_length {
    display: none !important;
  }

  .page-header {
    display: none !important;
  }
  .info {
    display: none !important;
  }
  .btn {
    display: none !important;
  }
}

input,
.StripeElement {
  display: block;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.rdw-editor-main {
  background-color: #f5f6f9;
  border: 1px solid #eee;
  margin-bottom: 17px;
  padding: 7px 17px 7px 17px;
  line-height: 27px;
}
.public-DraftEditor-content {
  min-height: 100px;
}

/* questionnaire printing */

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}
td{
  cursor: pointer;
}
.lien-bleu-souligne {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}
.a4 {
  width: 210mm; /* Largeur A4 */
  border: 1px solid #000; /* Bordure pour mieux voir la taille */
  box-sizing: border-box; /* Inclut les bordures dans les dimensions */
  padding: 10mm; /* Un peu d'espace intérieur pour le confort visuel */
  margin: 0 auto; /* Centre la div horizontalement */
}


.tableblack, .tableblack thead tr th, .tableblack  tbody tr td {
  border: 1px solid black !important; /* Applique une bordure solide noire à toutes les cellules */
}

.tableblack  th, .tableblack td {
  text-align: center !important; /* Centre le texte dans les cellules */
}

.tableblack {
  width: 100%;
  border-collapse: collapse;
  color: #fff; /* Texte en blanc */
}

.tableblack th, .tableblack td {
  border: 1px solid #fff; /* Bordures blanches */
  padding: 8px;
  text-align: center;
}
@media content {  
  @page {  
      size: A4;  
      margin: 20mm; /* Ajustez les marges si nécessaire */  
      color: black !important;
  }  
}  