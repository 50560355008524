.nomadis-info {
    text-align: center;
    position: absolute;
    top: 49px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px; /* Vous pouvez ajuster cette marge selon vos besoins */
  }
  
  .adresse {
    position: absolute;
    top: 50px;
    right: 0;
    margin: 20px; /* Vous pouvez ajuster cette marge selon vos besoins */
    text-align: right;
  }
  .headerr {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 21%;
    padding-bottom: 2.75rem;
    padding-top: 4.75rem;
}
.paragraphe{
  margin-top: 30px;
}
.date{
  margin-left: 80%;
  margin-top: 100px;
}

.logo_entreprise{
  width: 150px;
  height: 113px;
  margin-top: 44px;
}
.logo-img {
  width: 100%; /* Assurez-vous que le logo occupe la largeur complète sur mobile */
  margin-bottom: 10px; /* Ajoutez une marge en bas pour l'espacement */
}
 

 
/* Ajoutez des styles spécifiques pour les écrans de moins de 768px (mobile) */
 
 /* @media (max-width: 767px) {
  .print-attestation-container {
    padding: 10px; 
  }

  .logo_entreprise {
    text-align: center;  
    margin-left: 30%;
  }

  .nomadis-info,
  .adresse {
    text-align: center;  
    margin-top: 250px;
  }

  .adresse {
    margin-top: 180px; 
    text-align: justify;
  }

  .headerr {
    text-align: center;  
    padding-top: 20.75rem;
  }
  .logo-img {
    width: auto;  
    margin-bottom: 0;  
  }
 } */

